import React from 'react';

const Eduation = () => {
  return (
    <React.Fragment>
      <div>
        <b>
          Lorem ipsum dolor sit amet consectetur adipisicing elit. Velit ut
          quasi eos placeat alias hic magnam, natus, magni molestiae saepe non
          aut expedita. Repellendus ipsum hic incidunt nihil enim dolores.
        </b>
      </div>
    </React.Fragment>
  );
};

export default Eduation;
