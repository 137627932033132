import React from 'react';
import AboutMe from './aboutMe';
import Education from './education';

const Me = () => {
  return (
    <div className='main'>
      <section className='allContent'>
        <div className='myInfo'>
          <div className='aboutMe'>
            <AboutMe />
          </div>
        </div>
        <div className='rightSection'>
          <div className='rightSection_up'>
            <Education />
          </div>
          <div className='rightSection_down'>
            <span>
              Lorem ipsum dolor sit amet consectetur adipisicing elit. Magni
              facere non et consequatur vel ipsam amet, aliquam nam ullam
              explicabo esse, ipsa delectus sed ipsum facilis? Eaque nobis
              voluptatibus aut.
            </span>
          </div>
        </div>
      </section>
      <section className='foot'></section>
    </div>
  );
};

export default Me;
