import React from 'react';
import image from '../image/testImg.png';

const AboutMe = () => {
  return (
    <React.Fragment>
      <section className='conctactInfo'>
        <div className='myImg'>
          <img src={image} alt='' />
          <span className='fullName'>
            <h2>Mukunda George</h2>
          </span>
        </div>

        <div className='contactSkill'>
          <p>
            <i className='fa fa-briefcase' aria-hidden='true' />
            Web developer
          </p>
          <p>
            <i className='fa fa-home' aria-hidden='true' />
            Kigali / Rwanda
          </p>
          <p>
            <i className='fa fa-envelope-o' aria-hidden='true' />
            Mukundageorgej3@gmail.com
          </p>
          <p>
            <i className='fa fa-phone' aria-hidden='true' />
            +250784803240
          </p>
          <hr />
          <p>
            <i className='fa fa-book' aria-hidden='true' />

            <b>Skills</b>
          </p>
          <p className='skill'>HTML</p>
          <div className='mesureBox'>
            <div className='html'>90%</div>
          </div>
          <p className='skill'>CSS</p>
          <div className='mesureBox'>
            <div className='css'>75%</div>
          </div>
          <p className='skill'>Bootstrap</p>
          <div className='mesureBox'>
            <div className='bootstrap'>90%</div>
          </div>
          <p className='skill'>Javascript</p>
          <div className='mesureBox'>
            <div className='javascript'>70%</div>
          </div>
          <p className='skill'>Nodejs(Express)</p>
          <div className='mesureBox'>
            <div className='node'>70%</div>
          </div>
          <p className='skill'>MongoDB(Mongoose)</p>
          <div className='mesureBox'>
            <div className='mongo'>80%</div>
          </div>
          <p className='skill'>Ejs</p>
          <div className='mesureBox'>
            <div className='ejs'>80%</div>
          </div>
          <p className='skill'>Reactjs</p>
          <div className='mesureBox'>
            <div className='react'>60%</div>
          </div>
          <p>
            <i className='fa fa-globe' aria-hidden='true'></i>
            <b>Languages</b>
          </p>
          <p className='skill'>French</p>
          <div className='mesureBox'>
            <div className='french'></div>
          </div>
          <p className='skill'>English</p>
          <div className='mesureBox'>
            <div className='english'></div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default AboutMe;
